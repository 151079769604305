<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Biopori List">
      <b-button
        v-show="isAdmin"
        variant="outline-primary"
        class="bg-gradient-primary mt-2 side-button"
        type="submit"
        @click="addItem"
      >
        <span class="align-middle">Add Biopori</span>
      </b-button>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="dataRows"
        :is-loading="loading"
        style-class="vgt-table striped"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom'
        }"
      >
        <div
          slot="emptystate"
          align="center"
        >
          No Data
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'Status'"
            v-b-modal.modal-center
            :title="props.row.Komentar"
          >
            <b-badge :variant="statusVariant(props.row.Status)">
              {{ props.row.Status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none nopad"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <b-dropdown-item
                  v-show="isAdmin"
                  @click="validateData({id:props.row.id,
                                        quantity: props.row.Quantity,
                                        location: props.row.Area,
                                        locationID: props.row.AreaID,
                                        photo: props.row.photo,
                                        condition: props.row.Condition,
                                        order: 'edit'})"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-show="isAdmin"
                  @click="validateData({id:props.row.id, location: props.row.Area, order: 'delete'})"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','15','30','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>

            <div>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2"
                  pill
                  :disabled="!prev"
                  size="sm"
                  @click="prev ? getDisposisi('prev') : ''"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </b-button>
              </template>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2 ml-1"
                  pill
                  :disabled="!next"
                  size="sm"
                  @click="next ? getDisposisi('next') : ''"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </b-button>
              </template>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-center"
        ref="modal"
        v-model="modalShow"
        centered
        title="Form Add Biopori"
        cancel-title="Cancel"
        ok-title="Add"
        @ok="validationForm"
      >
        <b-row class="match-height">
          <validation-provider
            #default="{ errors }"
            name="Location"
            rules="required"
          >
            <b-form-group
              label="Location"
              label-for="Location"
            >
              <v-select
                v-model="selectedArea"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Select Area"
                :options="optionsArea"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Quantity"
            rules="required||numeric"
          >
            <b-form-group
              label="Quantity"
              label-for="Quantity"
            >
              <b-form-input
                id="Quantity"
                v-model="quantity"
                placeholder="Input Quantity"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Condition"
            rules="required"
          >
            <b-form-group
              label="Condition"
              label-for="Condition"
            >
              <v-select
                v-model="Condition"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Select Area"
                :options="['Ok', 'Repair']"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="Photo"
            label-for="Photo"
          >
            <!-- Styled -->
            <b-form-file
              id="file"
              ref="file"
              type="file"
              placeholder="Input Photo"
              drop-placeholder="Drop file here..."
              multiple="multiple"
              @change="fileChange"
            />
          </b-form-group>
        </b-row>
      </b-modal>

      <b-modal
        id="modal-detete"
        ref="modaldelete"
        v-model="modalDelete"
        centered
        :title="`${selectedItem.order === 'edit' ? 'Edit' : 'Remove'} Biopori`"
        cancel-title="Cancel"
        :ok-title="selectedItem.order === 'delete' ? 'Remove' : 'Save'"
        @ok="execute"
      >
        <div v-show="selectedItem.order === 'edit'">
          <validation-provider
            #default="{ errors }"
            name="Location"
            rules="required"
          >
            <b-form-group
              label="Location"
              label-for="Location"
            >
              <v-select
                v-model="selectedArea"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Select Area"
                :options="optionsArea"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Quantity"
            rules="required||numeric"
          >
            <b-form-group
              label="Quantity"
              label-for="Quantity"
            >
              <b-form-input
                id="Quantity"
                v-model="quantity"
                placeholder="Input Quantity"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Condition"
            rules="required"
          >
            <b-form-group
              label="Condition"
              label-for="Condition"
            >
              <v-select
                v-model="Condition"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Select Area"
                :options="['Ok', 'Repair']"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div
            v-if="!toggleEdit"
            class="photo"
          >
            <span> Photo </span>
            <div>
              <img :src="`https://api.arginipratamaputra.com${Photo}`">
            </div>
            <span
              class="link"
              @click="toggleEdit = !toggleEdit"
            > Change Photo </span>
          </div>

          <b-form-group
            v-else
            label="Photo"
            label-for="Photo"
          >
            <!-- Styled -->
            <b-form-file
              id="Photo"
              ref="file"
              type="file"
              placeholder="Input Photo"
              drop-placeholder="Drop file here..."
              multiple="multiple"
              @change="fileChange"
            />
            <span
              class="link"
              @click="toggleEdit = !toggleEdit"
            > View Photo </span>
          </b-form-group>
        </div>
        <span v-show="selectedItem.order === 'delete'"> Remove biopori area <b> {{ selectedItem.location }} </b> </span>
      </b-modal>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
  BFormDatepicker,
  BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    numeric,
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    vSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    BFormDatepicker,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      fromPage: 0,
      toPage: 0,
      modalShow: false,
      modalDelete: false,
      toggleEdit: false,
      Condition: '',
      isAdmin: false,
      area: '',
      Location: '',
      quantity: '',
      prev: '',
      next: '',
      selectedItem: {},
      optionsArea: [],
      selectedArea: [],
      file: '',
      columns: [
        {
          label: 'Area',
          field: 'Area',
        },
        {
          label: 'Quantity',
          field: 'Quantity',
        },
        {
          label: 'Updated',
          field: 'UpdatedAt',
        },
        {
          label: 'Condition',
          field: 'Condition',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          Area: '',
          Quantity: '',
          UpdatedAt: '',
          AreaID: '',
          Description: '',
          authorize: false,
          Location: '',
          Action: '',
          Photo: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Procces',
          2: 'Proses',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Proses: 'light-success',
        Failed: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    this.getDisposisi()
    this.getArea()
  },
  methods: {
    // detailDisposisi(e) {
    //   window.location.href = `detail-disposisi/${e}`
    // },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.add()
          this.$refs.modal.hide()
        }
      })
    },
    addItem() {
      this.area = ''
      this.quantity = ''
      this.selectedArea = []
      this.Photo = ''
      this.Condition = ''
      this.modalShow = true
    },
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.Photo = data
    },
    validateData(val) {
      this.modalDelete = !this.modalDelete
      this.area = val.name
      this.quantity = val.quantity
      this.selectedArea = { value: val.locationID, text: val.location }
      this.Photo = val.photo
      this.Condition = val.condition
      this.selectedItem = val
    },
    async getArea() {
      const { data } = await axios.get('/area')
      this.optionsArea = data.data.map(e => ({
        value: e.id,
        text: e.area,
      }))
    },
    execute() {
      if (this.selectedItem.order === 'delete') {
        this.delete()
      } else {
        this.edit()
      }
    },
    async delete() {
      await axios
        .delete(`ground/${this.selectedItem.id}`)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
    async edit() {
      await axios
        .put('ground', {
          id: this.selectedItem.id,
          // area: this.name,
          jumlah: Number(this.quantity),
          lokasi: this.selectedArea.text,
          area_id: this.selectedArea.value,
          foto: this.Photo,
          kondisi: this.Condition,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getDisposisi(val) {
      const defaultUrl = '/ground'
      let url = ''

      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else if (val) {
        if (val.order === 'get2') {
          url = `/ground?page=${val.page}`
        }
      } else {
        url = defaultUrl
      }

      const { data } = await axios.get(url, {
        params: {
          // page: this.page === 0 ? null : this.page,
        },
      })
      this.prev = data.prev_page_url
      this.next = data.next_page_url
      this.totalPage = data.total
      this.currentPage = data.current_page
      // this.totalRow = data.per_page
      this.fromPage = data.from
      this.toPage = data.to
      this.pageLength = data.per_page
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          Area: e.area !== null ? e.area.area : '-',
          Quantity: e.jumlah || '-',
          AreaID: e.area_id,
          Description: e.keterangan,
          Condition: e.kondisi,
          UpdatedAt: dayjs(e.updated_at).format('DD-MM-YYYY') || '-',
          photo: e.foto,
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },

    async add() {
      await axios
        .post('ground', {
          jumlah: Number(this.quantity),
          area_id: this.selectedArea.value,
          kondisi: this.Condition,
          foto: this.Photo,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('MMMM D, YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }

      return formatted
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}

.photo {
  span {
    margin-bottom: 10px;
  }
  div {
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.link {
  margin-top: 10px;
  display: block;
  color: #23425e;
  cursor: pointer;
  font-weight: bold;
}
</style>
